angular.module('comcast.common.communication').service('RestrictFileService', ['NotificationService', function (NotificationService) {
  var restrictedVideoTypes = ['m4v', 'wmv'];

  function checkRestrictedTypes(fileName) {
    var uploadArray = fileName.split('.');
    var extension = uploadArray[uploadArray.length - 1];

    if (restrictedVideoTypes.indexOf(extension) > -1) {
      NotificationService.showNotificationToast('Invalid file type selected, please try a different file type.');
      return false;
    }

    return true;
  }

  return {
    checkRestrictedTypes: checkRestrictedTypes
  };
}]);